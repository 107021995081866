<template>
    <div class="selectClass">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.go(-1)">
            <template #left class="left">
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span style="font-size:17px;font-weight:500;">套餐包含课包列表</span>
            </template>
        </van-nav-bar>

        <div class="title_box">
            <div v-for="(item,index) in typeList" :key="index" @click="setTypes(item,index)">
                <div :class="ids==index?'title_box_item active_title_box_item':'title_box_item'">
                    {{item.name}}
                </div>

            </div>

        </div>

        <div class="list">
            <div class="item" v-for="(course, key) in ActiveList" :key="key" @click="setDetails(course)">
                <div class="right">
                    <div class="title">
                        <!-- type：0录播 1直播  -->
                        <img v-if="course.type == 0" class="title_img" src="@/assets/all_Icon/drawable-xxhdpi/icon_recording.png" />
                        <img v-if="course.type == 1" class="title_img" src="@/assets/all_Icon/drawable-xxhdpi/icon_live.png" />
                        {{ course.name }}
                    </div>

                    <div class="text">
                        <div class="question_cirle-continer">
                            <div class="question_cirle-yes" :style="setCirleNum(course)"></div>
                        </div>
                        <div>{{course.studyCourseCount}}/{{course.courseCount}}课时</div>

                    </div>
                    <div class="mark">
                        <div class="mark_title"></div>
                        <div class="mark_study">继续学习</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { courseList, liveList_tc } from "@/api/study_course.js";
export default {
    data() {
        return {
            ActiveList: [],
            typeList: [],
            ids: -1
        };
    },
    created() {
        this.ActiveList = [];
        this.typeList = [];
        this.init();
    },
    methods: {
        setTypes(item, index) {
            this.ids = index;
            courseList(this.$route.query.id, item.id).then((res) => {
                if (res.data.code == 0) {
                    this.ActiveList = res.data.data.courseList;
                } else {

                }
            });
        },
        setCirleNum(item) {
            let num = item.studyCourseCount / item.courseCount * 100 + "%";
            if ((item.studyCourseCount / item.courseCount * 100) > 100) {
                num = 100 + "%";
            }
            return {
                "width": num
            }
        },
        setDetails(item) {
            console.log('meallist页-item---',item)
            // 跳视频
            if(item.type == 0){
                this.$router.push('/videoPage/'+ this.$route.params.id+ '/'+ item.id+ '/'+ item.recentlyStudyClassHourId)
            }
            // 跳直播
            else{
                this.$router.push({ path: "/list", query: { id: item.id,coursePackageId:this.$route.query.id } })
            }
        },
        init() {
            console.log('this.$route.params.id--',this.$route.params.id);
            courseList(this.$route.params.id, '').then((res) => {
                // console.log('meallist数据---',res)
                if (res.data.code == 0) {
                    this.ActiveList = res.data.data.courseList;
                    this.typeList = res.data.data.types;
                } else {

                }
            });
        },

        onRefresh(type) {
            console.log("下拉刷新type---", type);
        },
    },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
}
.title_box {
    margin-top: 15px;
    width: calc(100% - 14px);
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
    margin-left: 5px;
    margin-right: 7px;
    overflow-x: auto;
    .title_box_item {
        margin-left: 7px;
        width: 110px;
        height: 36px;
        background: #f8f8f8;
        border-radius: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        line-height: 36px;
        text-align: center;
    }
    .active_title_box_item {
        background: #e7ecff;
        color: #5d7dff;
    }
}
.selectClass {
    height: 100vh;
    font-family: PingFangSC-Medium, PingFang SC;
    position: relative;
    // background-color: pink;
    .list {
        padding: 0px 12px;
        .item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 14px 0;
            &:last-child {
                border: none;
            }

            .right {
                width: 100%;
                border-bottom: 1px solid #dddddd;
                padding-bottom: 15px;
                .title {
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    color: #333333;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-wrap: break-word;
                    .title_img {
                        width: 30px;
                        height: 15px;
                        margin-right: 10px;
                    }
                }
                .text {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-wrap: break-word;
                    margin: 6px 0 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .question_cirle-continer {
                        width: calc(100% - 93px);
                        height: 10px;
                        background: #e7ecff;
                        border-radius: 5px;
                        .question_cirle-yes {
                            width: 50%;
                            height: 10px;
                            background: #5f7dff;
                            border-radius: 5px;
                        }
                    }
                }
                .mark {
                    font-size: 13px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    line-height: 18px;
                    display: flex;
                    justify-content: space-between;
                    .mark_title {
                        font-size: 12px;
                        color: #cccccc;
                    }
                    .mark_study {
                        color: #ffffff;
                        width: 69px;
                        height: 25px;
                        background: #5f7dff;
                        border-radius: 17px;
                        text-align: center;
                        line-height: 25px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>

